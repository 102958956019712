import React from "react";
import Layout from "../components/layout";
import "../style/index.scss";

export default function Home() {
  return (
    <Layout>
      <h1>
        <head>
          <title>Crimble Consulting Ltd</title>
          <meta name="description" content="Crimble Consultancy is a UK based building consultancy with over 35 years of experience. Crimble deliver customer service that goes above and beyond."></meta>
        </head>
      </h1>
      <h2>
      Building consultancy
      </h2>
      <br></br>
      <br></br>
      <p>
      Crimble Consultancy is a UK based building consultancy with over 35 years of experience. 
      <br></br>
      <br></br>
      Customer satisfaction is paramount, we deliver a service that goes above and beyond.
      <br></br>
      <br></br>
      We offer a variety of services including:
      <ol>Commercial Services, 
        PFI & FM, 
        Lifecycle Advice & Costing and 
        Project management.</ol>
      <br></br>
      <br></br>
      To find out more, please visit the Services page.
      </p>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  )
};